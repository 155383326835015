import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import html2canvas from 'html2canvas';
import { CertificateService, CustomerType, CvPetsitterTemplateComponent, Mail, MailService, Petsitter, RequestUtilitiesService, VirtualScrollComponent } from 'npx-family-happy-common';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { ExampleModalComponent } from 'src/app/modals/example-modal/example-modal.component';
import { GoogleMapsService } from 'src/app/services/maps-service/google-maps.service';
import { PetsitterService } from 'src/app/services/petsitter-service/petsitter.service';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { ModalLoaderComponent } from 'src/app/shared/components/modal-loader/modal-loader.component';
import { trimSpaces } from 'src/app/shared/utils/trim-spaces.util';
import { createAddressValidator } from 'src/app/shared/validators/address.directives';
import { createCheckboxesValidator } from 'src/app/shared/validators/checkboxes.directives';
import { createSpacesValidator } from 'src/app/shared/validators/spaces.directives';
import { environment } from 'src/environments/environment';
import { v4 as UUID } from 'uuid';
import { ToFormControlPipe } from "../../shared/pipes/to-form-control.pipe";

@Component({
  selector: 'app-petsitter-form',
  templateUrl: './petsitter-form.component.html',
  styleUrls: ['./petsitter-form.component.scss'],
  standalone:true,
  imports: [CommonModule, ReactiveFormsModule, ExampleModalComponent, ModalLoaderComponent, ImageCropperComponent,
    CvPetsitterTemplateComponent, ToFormControlPipe, VirtualScrollComponent]
})
export class PetsitterFormComponent implements OnInit, AfterViewInit, OnDestroy {
  showModal = false;
  showExample = false;
  showSpinner = false;
  showSpinnerExemple = false;
  showSpinnerPhoto = false;
  modalLoader = false;
  exampleType!: string;
  petsitterID!: string;
  petsitter!: Petsitter;
  finalPhoto!: string;
  hasPayed!: boolean;
  globalDocRef!: string;
  private oldAddress!: string | undefined

  generatePages: boolean = false;
  cvPages!: number;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];

  form = new UntypedFormGroup({
    step1: new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      surname: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl(''),
      cap: new UntypedFormControl(null),
      birthDate: new UntypedFormControl(undefined, Validators.required),
      provincia: new UntypedFormControl('TO', Validators.required)
    }),
    step2: new UntypedFormGroup({
      cluster1: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          peeing: new UntypedFormControl(false),
          games: new UntypedFormControl(false),
          social: new UntypedFormControl(false),
          muzzle: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        game: new UntypedFormControl(''),
        help: new UntypedFormControl('')
      }),
      cluster2: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          interactions: new UntypedFormControl(false),
          walking: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        })
      }),
      cluster3: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          helpers: new UntypedFormControl(false),
          diseases: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        help: new UntypedFormControl('')
      }),
      license: new UntypedFormControl(false, Validators.required),
      car: new UntypedFormControl(false, Validators.required),
      minWage: new UntypedFormControl(null),
      maxWage: new UntypedFormControl(null),
      roles: new UntypedFormGroup({
        homeService: new UntypedFormControl(false),
        hosting: new UntypedFormControl(false),
        grooming: new UntypedFormControl(false),
        walking: new UntypedFormControl(false),
        pension: new UntypedFormControl(false),
        veterinarian: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        nightShifts: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false)
      }, createCheckboxesValidator(1))
    }),
    step3: new UntypedFormGroup({
      certificates: new UntypedFormArray([
        new UntypedFormControl('')
      ]),
      title: new UntypedFormControl('', Validators.required),
      graduationType: new UntypedFormControl('')
    }),
    step4: new UntypedFormGroup({
      otherExp: new UntypedFormGroup({
        drugs: new UntypedFormControl(false),
        disability: new UntypedFormControl(false),
        mentalIllness: new UntypedFormControl(false)
      }),
      weight: new UntypedFormGroup({
        dog: new UntypedFormControl(false),
        dog1: new UntypedFormControl(false),
        dog2: new UntypedFormControl(false),
        dog3: new UntypedFormControl(false),
        dog4: new UntypedFormControl(false),
        cat: new UntypedFormControl(false)
      }),
      weightHome: new UntypedFormGroup({
        dog: new UntypedFormControl(false),
        dog1: new UntypedFormControl(false),
        dog2: new UntypedFormControl(false),
        dog3: new UntypedFormControl(false),
        dog4: new UntypedFormControl(false),
        cat: new UntypedFormControl(false)
      }),
      daysAvail: new UntypedFormGroup({
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
        sun: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      hoursAvail: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(170), Validators.maxLength(340)]),
      photo: new UntypedFormControl('', Validators.required)
    })
  });
  formStep = 1;
  descriptionLength: number = 0;
  chosenPhoto!: string;
  lastUpdateTimestamp!: Date;
  isNew: boolean = true;
  private unsubscribe = new Subject<void>();

  availQuestions = [
    {
      question: "Lei ha una patente in corso di validità?",
      form: "license"
    },
    {
      question: "Lei è automunita?",
      form: "car"
    }
  ];

  constructor(private petsitterService: PetsitterService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private googleMapsService: GoogleMapsService,
    private utilities: RequestUtilitiesService,
    private mailService: MailService,
    private certificateService: CertificateService
  ) { }

  ngOnInit(): void {
    this.form.get('step4')?.get('description')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.descriptionLength = value.length;
    });

    this.route.queryParams.subscribe((params) => {
      const docRef = params['id'];
      this.globalDocRef = params['id'];
      if (docRef) {
        this.petsitterService.getSinglePetsitter(docRef).pipe(takeUntil(this.unsubscribe)).subscribe((petsitters) => {
          let petsitter = petsitters[0];
          this.petsitter = petsitter;
          this.oldAddress = petsitter.info.address
          this.initForm(petsitter);
          this.lastUpdateTimestamp = petsitter.updateTimestamp;
          this.isNew = false;

        })
      }
    });

    //Conditional form validators

    for (let i = 1; i < 4; i++) {
      this.form.get(`step2.cluster${i}.expYears`)?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (value) {
          this.form.get(`step2.cluster${i}.tasks`)?.setValidators(createCheckboxesValidator(1))
        } else {
          this.form.get(`step2.cluster${i}.tasks`)?.clearValidators();
          this.form.get(`step2.cluster${i}.tasks`)?.reset();
        }
      })
    }

    this.form.get('step2.cluster1.tasks.games')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster1.game')?.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.get('step2.cluster1.game')?.clearValidators();
        this.form.get('step2.cluster1.game')?.reset();
      }
    })

    this.form.get('step2.cluster1.tasks.other')?.valueChanges.subscribe(value => {
      console.log('Other in cluster 1 tasks selected:', value);
      if (value) {
        this.form.get('step2.cluster1.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.get('step2.cluster1.help')?.clearValidators();
        this.form.get('step2.cluster1.help')?.reset();
      }
    })

    this.form.get('step2.cluster3.tasks.helps')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value) {
        this.form.get('step2.cluster3.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.get('step2.cluster3.help')?.clearValidators();
        this.form.get('step2.cluster3.help')?.reset();
      }
    })
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('step1.address');
        let formCity = this.form.get('step1.city');
        let formCap = this.form.get('step1.cap');

        place.address_components?.forEach(component => {
          component.types.forEach(type => {
            addressParts.push(type);
          });

          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
        });

        formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);

        formAddress?.setValue(place.formatted_address);
      })
    });
  }

    get step2() {
      return this.form.get('step2') as FormGroup;
    }

    get minWage() {
      return this.step2.controls['minWage'] as FormControl;
    }
    get maxWage() {
      return this.step2.controls['maxWage'] as FormControl;
    }

  changeStep(next: boolean) {
    if (next === true) {
      this.formStep++
      this.submitFormStep();
    } else {
      this.formStep--
    }
  }

  async submitFormStep() {
    this.petsitter = await this.processFormData();
    this.petsitterService.updatePetsitterFromForm(this.petsitter).pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.petsitterID = res.message.match(/[\w-]+$/)[0];
      this.globalDocRef = this.petsitterID;
    });
  }

  touchButton() {
    this.form.get('step4')?.get('photo')?.markAsTouched({ onlySelf: true });
    this.showSpinnerPhoto = true;
  }

  openModal(event: any) {
    this.showSpinnerPhoto = true;
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }
  abortSelection(event: any) {
    this.showSpinnerPhoto = false;
  }

  openExamplePhoto() {
    this.exampleType = 'PetPhoto';
    this.showExample = true;
    this.showSpinnerExemple = true;
  }

  closeExampleModal() {
    this.showExample = false;
    this.showSpinnerExemple = false;
  }

  getAge(date: string) {
    let today = new Date();
    let birthday = new Date(date);
    let age = today.getFullYear() - birthday.getFullYear();
    let month = today.getMonth() - birthday.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  initForm(petsitter: Petsitter) {
    this.form.setValue({
      step1: {
        name: trimSpaces(petsitter.info.name),
        surname: trimSpaces(petsitter.info.surname),
        email: petsitter.info.email,
        phone: petsitter.info.phone,
        address: petsitter.info.address,
        city: petsitter.info.city,
        cap: petsitter.info.cap,
        birthDate: petsitter.info.birthDate,
        provincia: petsitter.info.provincia,
      },
      step2: {
        cluster1: {
          expYears: petsitter.exp.cluster1.cluster1Age,
          employer: petsitter.exp.cluster1.employer1,
          tasks: this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster1.tasks1, 'tasks1'),
          game: petsitter.exp.cluster1.games ?? '',
          help: petsitter.exp.cluster1.help1 ?? ''
        },
        cluster2: {
          expYears: petsitter.exp.cluster2.cluster2Age,
          employer: petsitter.exp.cluster2.employer2,
          tasks: this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster2.tasks2, 'tasks2')
        },
        cluster3: {
          expYears: petsitter.exp.cluster3.cluster3Age,
          employer: petsitter.exp.cluster3.employer3,
          tasks: this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster3.tasks3, 'tasks3'),
          help: petsitter.exp.cluster3.help2
        },
        roles: {
          homeService: petsitter.avail.homeService,
          hosting: petsitter.avail.hosting,
          grooming: petsitter.avail.grooming,
          walking: petsitter.avail.walking,
          pension: petsitter.avail.pension,
          veterinarian: petsitter.avail.veterinarian,
          cleaning: petsitter.avail.cleaning,
          nightShifts: petsitter.avail.nightShift,
          relocation: petsitter.avail.relocation,
          coliving: petsitter.avail.coliving
        },
        license: petsitter.avail.license,
        car: petsitter.avail.car,
        minWage: petsitter.avail.minWage ? petsitter.avail.minWage : null,
        maxWage: petsitter.avail.maxWage ? petsitter.avail.maxWage : null,
      },
      step3: {
        certificates: [''],
        title: petsitter.lang.title,
        graduationType: petsitter.lang.graduationType
      },
      step4: {
        otherExp: {
          drugs: petsitter.lang.drugs,
          disability: petsitter.lang.disability,
          mentalIllness: petsitter.lang.mentalIllness
        },
        weight: { ...this.petsitterService.fromStringToAvailObject(petsitter.avail.catHealing, 'catHealing'), ...this.petsitterService.fromStringToAvailObject(petsitter.avail.dogHealing, 'dogHealing') },
        weightHome: { ...this.petsitterService.fromStringToAvailObject(petsitter.avail.catHosting, 'catHosting'), ...this.petsitterService.fromStringToAvailObject(petsitter.avail.dogHosting, 'dogHosting') },
        daysAvail: this.petsitterService.fromStringToAvailObject(petsitter.avail.days, 'days'),
        hoursAvail: this.petsitterService.fromStringToAvailObject(petsitter.avail.hours, 'hours'),
        description: petsitter.info.description,
        photo: petsitter.info.photo
      }
    }, { emitEvent: false })
    this.setCertificatesLenght(petsitter.certificates.petsitterCertificateList);
  }

  async processFormData(updateTimestamp?: Date) {
    let cluster1Tasks: string = '';
    Object.entries(this.form.get("step2.cluster1.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster1Tasks += `${key} `;
      } else {
        return;
      }
    });

    let cluster2Tasks: string = '';
    Object.entries(this.form.get("step2.cluster2.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster2Tasks += `${key} `;
      } else {
        return;
      }
    });

    let cluster3Tasks: string = '';
    Object.entries(this.form.get("step2.cluster3.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster3Tasks += `${key} `;
      } else {
        return;
      }
    });

    let clustersExp = [];
    clustersExp.push(this.form.get('step2.cluster1.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster2.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster3.expYears')?.value);
    let age = this.getAge(this.form.get('step1.birthDate')!.value);
    let expSum = clustersExp[0] + clustersExp[1] + clustersExp[2];
    let totalExp = Math.max(...clustersExp, Math.min(expSum, (age - 16)));

    let catHealing = false;
    let catHosting = false;
    let petSizes: string = '';
    Object.entries(this.form.get('step4.weight')?.value).forEach(([key, value]) => {
      if (value) {
        if (key !== 'cats') {
          petSizes += `${key} `;
        } else {
          catHealing = true;
        }
      } else {
        return;
      }
    });

    let petSizesHome: string = '';
    Object.entries(this.form.get('step4.weightHome')?.value).forEach(([key, value]) => {
      if (value) {
        if (key !== 'cats') {
          petSizesHome += `${key} `;
        } else {
          catHosting = true;
        }
      } else {
        return;
      }
    });

    let petsitter: Petsitter = {
      docRef: this.globalDocRef,
      certificate: '',
      info: {
        name: this.form.get("step1")?.get("name")?.value,
        surname: this.form.get("step1")?.get("surname")?.value,
        email: this.form.get("step1")?.get("email")?.value,
        phone: this.form.get("step1")?.get("phone")?.value,
        address: this.form.get("step1.address")?.value,
        city: this.form.get("step1.city")?.value,
        zone: '',
        cap: this.form.get("step1.cap")?.value,
        age,
        birthDate: this.form.get("step1")?.get("birthDate")?.value,
        provincia: this.form.get("step1")?.get("provincia")?.value,
        fiscalcode: '',
        resume: '',
        resumeHidden: '',
        video: '',
        notes: '',
        iban: '',
        description: this.form.get("step4")?.get("description")?.value,
        photo: this.form.get("step4")?.get("photo")?.value,
        subscription: this.hasPayed ? 'ABBONATO' : '',
        manualSubscription: this.petsitter ? this.petsitter.info.manualSubscription : '',
        expDate: this.petsitter ? this.petsitter.info.expDate : null,
        expDateManual: this.petsitter ? this.petsitter.info.expDateManual : null,
        stopWhatsapp: this.petsitter ? this.petsitter.info.stopWhatsapp : false,
        latitude: this.petsitter ? this.petsitter.info.latitude : 0,
        longitude: this.petsitter ? this.petsitter.info.longitude : 0,
      },
      exp: {
        total: totalExp,
        cluster1: {
          cluster1Age: this.form.get("step2.cluster1.expYears")?.value,
          employer1: this.form.get("step2.cluster1.employer")?.value,
          tasks1: this.petsitterService.fromAvailObjectToString(this.form.get("step2.cluster1.tasks")?.value, 'tasks1'),
          games: this.form.get("step2.cluster1.game")?.value ?? null,
          help1: this.form.get("step2.cluster1.help")?.value ?? null
        },
        cluster2: {
          cluster2Age: this.form.get("step2.cluster2.expYears")?.value,
          employer2: this.form.get("step2.cluster2.employer")?.value,
          tasks2: this.petsitterService.fromAvailObjectToString(this.form.get("step2.cluster2.tasks")?.value, 'tasks2')
        },
        cluster3: {
          cluster3Age: this.form.get("step2.cluster3.expYears")?.value,
          employer3: this.form.get("step2.cluster3.employer")?.value,
          tasks3: this.petsitterService.fromAvailObjectToString(this.form.get("step2.cluster3.tasks")?.value, 'tasks3'),
          help2: this.form.get("step2.cluster3.help")?.value ?? null
        }
      },
      reference: {
        f_surname: '',
        f_second_surname: ''
      },
      lang: {
        drugs: this.form.get("step4.otherExp.drugs")?.value,
        mentalIllness: this.form.get("step4.otherExp.mentalIllness")?.value,
        disability: this.form.get("step4.otherExp.disability")?.value,
        title: this.form.get("step3.title")?.value ?? '',
        graduationType: this.form.get("step3.graduationType")?.value ?? null
      },
      avail: {
        catHealing: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("weight")?.value, 'catHealing'),
        catHosting: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("weightHome")?.value, 'catHosting'),
        dogHealing: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("weight")?.value, 'dogHealing'),
        dogHosting: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("weightHome")?.value, 'dogHosting'),
        homeService: this.form.get("step2.roles.homeService")?.value,
        pension: this.form.get("step2.roles.pension")?.value,
        walking: this.form.get("step2.roles.walking")?.value,
        hosting: this.form.get("step2.roles.hosting")?.value,
        grooming: this.form.get("step2.roles.grooming")?.value,
        veterinarian: this.form.get("step2.roles.veterinarian")?.value,
        cleaning: this.form.get("step2.roles.cleaning")?.value,
        nightShift: this.form.get("step2.roles.nightShifts")?.value,
        coliving: this.form.get("step2.roles.coliving")?.value,
        relocation: this.form.get("step2.roles.relocation")?.value,
        days: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("daysAvail")?.value, 'days'),
        hours: this.petsitterService.fromAvailObjectToString(this.form.get("step4")?.get("hoursAvail")?.value, 'hours'),
        contract: '',
        license: this.form.get("step2.license")?.value,
        car: this.form.get("step2.car")?.value,
        minWage: this.form.get("step2.minWage")?.value,
        maxWage: this.form.get("step2.maxWage")?.value,
      },
      rating: {
        overall: this.petsitter ? this.petsitter.rating.overall : 0,
        punctuality: this.petsitter ? this.petsitter.rating.punctuality : 0,
        empathy: this.petsitter ? this.petsitter.rating.empathy : 0,
        behave: this.petsitter ? this.petsitter.rating.behave : 0,
        communication: this.petsitter ? this.petsitter.rating.communication : 0,
        dangerous: this.petsitter ? this.petsitter.rating.dangerous : false,
        attitude: this.petsitter ? this.petsitter.rating.attitude : '',
        notRated: this.petsitter ? this.petsitter.rating.notRated : false,
      },
      engagement: {
        selected: this.petsitter ? this.petsitter.engagement.selected : 0,
        chosen: this.petsitter ? this.petsitter.engagement.chosen : 0,
        discarded: this.petsitter ? this.petsitter.engagement.discarded : 0,
        ignored: this.petsitter ? this.petsitter.engagement.ignored : 0,
        substituted: this.petsitter ? this.petsitter.engagement.substituted : 0,
        applications: this.petsitter ? this.petsitter.engagement.applications : 0,
        chosenDate: this.petsitter ? this.petsitter.engagement.chosenDate : null,
        latestApplication: this.petsitter ? this.petsitter.engagement.latestApplication : null
      },
      certificates: {
        petsitterCertificateList: this.form.get('step3.certificates')?.value[0] ? this.form.get('step3.certificates')?.value : []
      },
      updateTimestamp: updateTimestamp ?? this.lastUpdateTimestamp
    };

    //calculate coordinates from address
    if (this.oldAddress !== petsitter.info.address) {
      let coordinates = await this.getCoordiantes(petsitter)
      petsitter = {
        ...petsitter,
        info: {
          ...petsitter.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }

    } else {
      petsitter = {
        ...petsitter,
        info: {
          ...petsitter.info,
          latitude: petsitter.info.latitude,
          longitude: petsitter.info.longitude
        }
      }
    }

    this.petsitter = petsitter;

    return petsitter;
  }

  async submitForm() {
    this.modalLoader = true
    this.petsitter = await this.processFormData(new Date());
    this.petsitterService.createPetsitter(this.petsitter).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      console.log('Submit completion: ', res);
      /* if (environment.production) {
        console.log('Form data sent to Zapier');
      } */
      if (this.hasPayed || this.petsitter.info.manualSubscription === 'ABBONATO') {
        this.generateCV();
        // this.modalService.dismissAll()
      } else {
        this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name, category: 'PETSITTER' } });
        this.modalLoader = false
        this.modalService.dismissAll()
        /* if(this.petsitter.rating.attitude === '') {
          this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.petsitterID } });
        } else {
          this.router.navigate(['thank-you-petsitter/' + this.petsitter.info.email], {queryParams: {fromCV: true, name: this.petsitter.info.name}});
        } */
      }
      this.modalLoader = false
      // this.modalService.dismissAll()
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
    this.showSpinnerPhoto = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadImage(image: string) {
    const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
    const path = 'pictures/' + UUID() + '.png';
    let storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed', () => { }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
        console.log('File available at', downloadURL);
        this.form.get("step4")?.get("photo")?.setValue(downloadURL);
        alert('Foto caricata con successo!');
        this.showSpinnerPhoto = false;
      });
    })
    this.showModal = false;
  }
  //GENERATE CV
  async generateCV() {
    // this.modalLoader = true
    this.generatePages = true;
    setTimeout(() => { this.htmlToCanvas() }, 1000);
  }

  htmlToCanvas() {
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;

      this.petsitterService.getSinglePetsitter(this.petsitterID).subscribe(babysitters => {
        let babysitter = babysitters[0];
        let babysitterEmail = environment.production ? babysitter.info.email : 'drugle91xbox@gmail.com'; // Cambiamento temporaneo indirizzo email di test
        let senderEmail = this.utilities.getMailService(CustomerType.B2C, environment.production);
        let message = `<p>Car* ${babysitter.info.name},<br>
        hai completato con successo il tuo CV che trovi in allegato e che puoi scaricare.<br>
        Ti consigliamo di tenerlo sempre aggiornato per aver più successo nella ricerca del lavoro.<br>
        Puoi sempre aggiornarlo o modificarlo cliccando <a href="${environment.formBaseURL}/petsitter-form?id=${babysitter.docRef}">QUI</a>.</p>
        <p>Ti ricordiamo che riceverai aggiornamenti ad ogni tua candidatura.</p>
        <p>A presto e in bocca al lupo,<br>
        Il Team Di Family+Happy</p>`;
        let cv = {
          path: babysitter.info.resumeHidden,
          filename: `${babysitter.info.name} ${babysitter.info.surname} - Family+Happy CV.pdf`
        };

        let mail = new Mail(senderEmail, babysitterEmail, 'Il tuo CV Family+Happy è pronto', message, [cv]);

        this.mailService.sendCustom(environment.mailServiceURL, mail).subscribe({
          next: () => {
            console.log('Email sent.');
            // if (this.petsitter.rating.attitude === '') {
            //   this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.petsitterID } });
            // } else {
            this.modalLoader = false
            this.modalService.dismissAll()
            this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name, category: 'PETSITTER' } });
            // }
          },
          error: () => {
            console.error('Mail service error');
            this.modalLoader = false
            this.modalService.dismissAll()
            this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name, category: 'PETSITTER' } });
            // if (this.petsitter.rating.attitude === '') {
            //   this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.petsitterID } });
            // } else {
            //   this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name } });
            // }
          }
        })
      });
      return;
    } else {
      this.modalLoader = false
      this.modalService.dismissAll()
      this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name, category: 'PETSITTER' } });
      // this.router.navigate(['thank-you/' + this.petsitter.info.email], { queryParams: { fromCV: true, name: this.petsitter.info.name, category: 'PETSITTER' } });
      // this.modalLoader = false
      /* if(this.petsitter.rating.attitude === '') {
        this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.petsitterID } });*/
    }

    const element = document.getElementById('cv-page-container') as HTMLElement;
    html2canvas(element, { allowTaint: true, useCORS: true }).then(async canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        await this.certificateService.createPetsittePDFCVFromHTML(this.canvases, this.petsitter, this.isNew, false,
          null, environment.petsitterServiceURL, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas() }, 1000);
    })
    // this.modalLoader = false;
    // this.modalService.dismissAll()
  }


  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('step3.certificates') as UntypedFormArray;

    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('step3.certificates') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('step3.certificates') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('step3.certificates') as UntypedFormArray;

    return array.controls;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }


  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Petsitter) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
